.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.app-color {
}
:root {
  color-scheme: light dark;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.app-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.main-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.main-nav button {
  margin-right: 8px;
}

.nav-bar {
  margin-top: 18;
  margin-bottom: 18;
}

.App-link {
  color: #61dafb;
}

.side-menu-button {
  border: 2px;
  margin-bottom: 25px;
}

.App-header {
  display: flex;
  justify-content: center;
}
.header-img {
  width: 468px;
  height: 60px;
}
.app-footer {
  display: flex;
  justify-content: center;
}
.footer-img {
  width: 468px;
  height: 60px;
}
.frame {
  flex: 1 1 auto;
}
.left-side-menu button {
  display: flex;
  justify: center;
  align-text: center;
  flex-direction:column;
  width:15vw;
  margin-bottom: 4px;
  margin-right: 18px;
}
.body-container {
  display: flex;
  flex-direction: row;
}
.body-content {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify: center;
  align-text: center;
}
.iframe-container {
  display:flex;
  justify-content: center;
  flex: 1 1 auto;
  height: 50vh;
  width: 50vw;
}

#external-app {
  overflow-y: auto;
  overflow-x: auto;
  resize: both;
  position: relative;
  z-index: 2;
}

iframe {
  width: 100%;
  height: 80vh;
  border: none;
  color-scheme: light dark;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
